
import { defineComponent, shallowRef } from 'vue'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import Pic from '@/components/Pic.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'

export default defineComponent({
  name: 'FirstDeposit',
  components: {
    Pic,
    PageWithHeader,
  },
  setup () {
    const isFirst = shallowRef(false)
    const recipients = shallowRef(0)
    const giftList = [{
      icon: 'events/firstDeposit/cover_1',
      name: 'firstdeposit_7',
      desc: 'firstdeposit_8',
    }, {
      icon: 'events/firstDeposit/cover_2',
      name: 'firstdeposit_9',
      desc: 'firstdeposit_10',
    }, {
      icon: 'events/firstDeposit/cover_3',
      name: 'firstdeposit_11',
      desc: 'firstdeposit_12',
    }, {
      icon: 'events/firstDeposit/cover_4',
      name: 'firstdeposit_13',
      desc: 'firstdeposit_14',
    }]
    checkIsFirstDeposit().then(res => {
      isFirst.value = Boolean(res.first)
      recipients.value = res.recipients
    })
    return {
      isFirst,
      recipients,
      giftList,
    }
  },
})
