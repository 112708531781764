<template>
<PageWithHeader class="bg_blue">
  <template #title>
    <t path="firstdeposit_2">First Deposit Bonus</t>
  </template>
  <div class="main-bd df-col">
    <div class="flex-1 scroll-y">
      <Pic src="events/firstDeposit/bg" class="main-bg" />
      <div class="bd px-16">
        <div class="df-middle px-16 br-lg c-bg" style="height:64px;">
          <Pic src="events/firstDeposit/VIP" width="48" height="44" />
          <t path="firstdeposit_3" class="c-white f-bold f-lg ml-8">
            1 Week Prime membership
          </t>
        </div>

        <div class="br-lg c-bg px-16 mt-16 pb-16">
          <div class="df-middle" style="height:64px;">
            <Pic src="events/firstDeposit/book" width="61" height="44" />
            <t path="firstdeposit_4" class="c-white f-bold f-lg">
              From Beginner To Master
            </t>
          </div>
          <div class="g-list px-16 pt-8 br-lg">
            <div class="g-item d-f mt-8" v-for="(item, index) in giftList" :key="index">
              <div class="b-cover df-middle df-center">
                <Pic :src="item.icon" width="40" height="60" />
              </div>
              <div class="ml-16">
                <t :path="item.name" as="p" class="f-bold" style="line-height: 24px;">Bookname</t>
                <t :path="item.desc" as="p" class="pb-16" style="line-height: 20px;"
                  :style="index==giftList.length-1?'':'border-bottom: 1px solid #CFD8E3;'">
                  Prime membership starts automatically after accepting
                </t>
              </div>
            </div>
          </div>
        </div>

        <div class="py-24">
          <t as="div" path="firstdeposit_5" multiline #="{td}" custom>
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>
      </div>
    </div>
    <div class="ft px-16 df-middle">
      <router-link to="/in" custom #="{navigate}">
        <button class="btn block" @click="navigate">
          <t path="firstdeposit_6" class="f-bold">Deposit</t>
        </button>
      </router-link>
    </div>
  </div>
</PageWithHeader>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import Pic from '@/components/Pic.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'

export default defineComponent({
  name: 'FirstDeposit',
  components: {
    Pic,
    PageWithHeader,
  },
  setup () {
    const isFirst = shallowRef(false)
    const recipients = shallowRef(0)
    const giftList = [{
      icon: 'events/firstDeposit/cover_1',
      name: 'firstdeposit_7',
      desc: 'firstdeposit_8',
    }, {
      icon: 'events/firstDeposit/cover_2',
      name: 'firstdeposit_9',
      desc: 'firstdeposit_10',
    }, {
      icon: 'events/firstDeposit/cover_3',
      name: 'firstdeposit_11',
      desc: 'firstdeposit_12',
    }, {
      icon: 'events/firstDeposit/cover_4',
      name: 'firstdeposit_13',
      desc: 'firstdeposit_14',
    }]
    checkIsFirstDeposit().then(res => {
      isFirst.value = Boolean(res.first)
      recipients.value = res.recipients
    })
    return {
      isFirst,
      recipients,
      giftList,
    }
  },
})
</script>

<style scoped lang="scss">
.bg_blue {
  background: #2C5ED7;

  ::v-deep(.page__header) {
    background: white;
  }
}

.main-bd{
  height: calc(100vh - var(--page-header-height));
}
.main-bg{
  width: 100vw;
  height: 80vw;
}
.c-bg{
  background: #548AFB;
  border: 1px solid #709EFF;
}
.g-list{
  background-color: var(--color-white);
  .g-item{
    .b-cover{
      max-width: 64px;
      min-width: 64px;
      height: 64px;
      background: var(--color-light);
    }
  }
}
.ft{
  width: 100%;
  height: 64px;
  background: #4678EF;
  button{
    height: 48px;
    background: linear-gradient(360deg, #FF8B00 0%, #FFDC3C 100%);
    border-radius: 24px;
    color: #811100;
    font-size: 21px;
  }
}
</style>
